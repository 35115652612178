
import { defineComponent, onMounted } from "vue";
import PermissionsTable from "@/components/admin/PermissionsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "individual-client-claim-history",
  components: {
    PermissionsTable
  },
  data() {
    return {
      claimsSummary: null,
      policySummary: null
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Permissions", ["All"]);
    });
  },
  created() {
    //Set page title
    document.title = "Permissions | " + process.env.VUE_APP_TITLE;

    //Fetch individual client dashboard data
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(variables.INDIVIDUAL_CLIENT_DASHBOARD)
        .then(({ data }) => {
          //Assign data to props
          this.claimsSummary = data.data.claimsSummary;
          this.policySummary = data.data.policySummary;
          $("#basicExample").DataTable({
            iDisplayLength: 10,
            language: {
              searchPlaceholder: "Search...",
              sSearch: "",
              lengthMenu: "_MENU_ items/page",
              // "lengthMenu": "Display _MENU_ records per page",
              zeroRecords: "No matching records found",
              // "info": "Showing page _PAGE_ of _PAGES_",
              infoEmpty: "No records available",
              infoFiltered: "(filtered from _MAX_ total records)"
            },
            processing: true
          });
        })
        .catch(function (error) {
          Swal.fire({
            text: error.response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-danger"
            }
          });
        });
    }
  }
});
