
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

export default defineComponent({
  name: "individual-client-claims-history-table",
  components: {},
  props: {
    widgetClasses: String,
    policySummary: Object
  }
  //   setup() {},
});
